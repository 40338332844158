import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div>
       <nav className="navbar bg-body-tertiary">
         <div className="container">
              <Link className="navbar-brand" to="/">
                 <img src="./assets/mbp_logo.webp" data-src='./assets/mbp_logo.webp' alt="Bootstrap" width="60" height="60" className='lazyload'/>
             </Link>
            
              <Link className="navbar-brand" to="/login">
                 <img src="./assets/user.webp" data-src='./assets/user.webp' alt="Bootstrap" width="40" height="40" className='lazyload'/>
              </Link>
         </div>
        </nav>
      
    </div>
  )
}

export default Navbar
