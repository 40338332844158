import React from 'react'
import Sidebar from './Sidebar';

const Askquestion = () => {

    return (

        <div>
            <Sidebar></Sidebar>
            <div className='container ' style={{ paddingTop: "80px" }}>
                <h3 className='text-center pt-3 pb-2'>Ask <span style={{ color: "#ff9933" }}> Question </span> ? </h3>
                <div className="form-floating ">
                    <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "150px", boxShadow: "none", border: "1px solid #ff9933" }}></textarea>
                    <label htmlFor="floatingTextarea2">Ask  Question </label>
                    <div className='text-end p-3'>
                        <button className='button_add'> send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Askquestion
