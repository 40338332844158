import React, { useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';


function OrdernowPopup(props) {
    const navigate = useNavigate();
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
                <div className='text-center'>
                    <h4>Are you Sure?</h4>
                    <h4>You want to order</h4>
                    {/* {
                        console.log("totalinpopup" ,props.grandtotal)
                    }
                    {
                        console.log('idinpopup',props.cartid)
                    } */}
                </div>

                <div className='d-flex justify-content-between'>
                    <button className='ordernow-popup-button' onClick={props.onHide} >No</button>
                    <button className='ordernow-popup-button' onClick={() => { orderNow(props.grandtotal, props.cartid, props.onHide, navigate) }}>Yes</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

async function orderNow(cartinfo, total, hide, navigate) {

    const storedToken = localStorage.getItem('token');
    console.log('orderid', total);
    console.log('total', cartinfo);

    // cartinfo is Total  and  total is cartinfo
    console.log(total);
    console.log(storedToken);
    // const  cartid = `${cartinfo}`
    // const  grandtotal = `${total}`

    try {
        const order = await fetch('http://art.alrawdah.in/api/auth/order', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${storedToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ "cart_info_id": `${total}`, "grand_total": `${cartinfo}` })

        })
        // console.log(order)

        if (order.status === 200) {
            const data = await order.json();
            toast.success(data.message);
            hide();
            navigate('/thankyoupage');
        }
        else if (order.status === 401) {
            toast.error('Unauthorized response');
        } else {
            toast.error('Something went wrong with the Add to cart request request.');
        }
    }
    catch (error) {
        toast.error(error)
    }
}
const Addtocart = () => {

    const [modalShow, setModalShow] = useState(false);

    const updatecart = (item, action) => {
        const updatedCartData = [...cartdata]; // Create a copy of the cart data

        // Find the index of the item in the cart data
        const itemIndex = updatedCartData.findIndex((cartItem) => cartItem.id === item.id);

        if (itemIndex !== -1) {
            // If the item exists in the cart data
            if (action === 'increase') {
                // Increase the quantity of the item
                updatedCartData[itemIndex].quantity += 1;
                Increasequantitycart(item, updatedCartData[itemIndex].quantity);

                console.log(updatedCartData[itemIndex].quantity)
            } else if (action === 'decrease' && updatedCartData[itemIndex].quantity > 1) {
                // Decrease the quantity of the item, but ensure it doesn't go below 1
                updatedCartData[itemIndex].quantity -= 1;
                Decreasequantitycart(item, updatedCartData[itemIndex].quantity); //
                console.log(updatedCartData[itemIndex].quantity)
            }

            // Update the state with the modified cart data
            setCartdata(updatedCartData);
        }
        async function Decreasequantitycart(item, quantity) {
            console.log("dec", item);
            // console.log(storedToken)
            // console.log("qonty",quantity)

            const Quantity = quantity
            const price = parseInt(item.rate, 10)
            try {
                const result = await fetch('http://art.alrawdah.in/api/auth/updatecart', {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${storedToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "cart_item_id": item.id, "item_price": price, "item_quantity": Quantity })
                })
                console.log(result)
                console.log(item.id, price, Quantity)
                if (result.ok) {
                    const data = await result.json();
                    console.log(data)
                    console.log("updated")
                    newcart()


                }
                else if (cartdata.status === 401) {
                    console.log('Unauthorized response');
                } else {
                    console.log('Something went wrong with the Add to cart request request.');
                }

            }
            catch (error) {
                console.log(error)
            }
        }
        async function Increasequantitycart(item, quantity) {
            console.log(item);
            // console.log(storedToken)
            // console.log("qonty",quantity)

            const Quantity = quantity
            const price = parseInt(item.rate, 10)
            try {
                const result = await fetch('http://art.alrawdah.in/api/auth/updatecart', {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${storedToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "cart_item_id": item.id, "item_price": price, "item_quantity": Quantity })
                })
                console.log(result)
                console.log(item.id, price, Quantity)
                if (result.ok) {
                    const data = await result.json();
                    console.log(data)
                    console.log("updated")

                    newcart()
                }

                else if (cartdata.status === 401) {
                    console.log('Unauthorized response');
                } else {
                    console.log('Something went wrong with the Add to cart request request.');
                }

            }
            catch (error) {
                console.log(error)
            }

        }
    };
    // updatecart view after increase or decrease the quantity
    async function newcart() {
        try {
            const response = await fetch('http://art.alrawdah.in/api/auth/viewcart', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCartdata(data);

        } catch (error) {
            console.error('Error in API:', error);
        }
    }
    // Delete Item Cart
    async function Deleteitem(item) {
        console.log(item);
        try {
            const deleteitem = await fetch('http://art.alrawdah.in/api/auth/deletecart', {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${storedToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "cart_item_id": item.id })
            })
            if (deleteitem.ok) {
                // If the DELETE request is successful, you can parse the response JSON if needed
                const data = await deleteitem.json();
                console.log(data);
                newcart()
                toast.success(data.message)

            } else {
                console.log('Delete request failed with status:', deleteitem.status);
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    //     const storedToken = localStorage.getItem('token');
    //    console.log(storedToken);
    const storedToken = useMemo(() => {
        return localStorage.getItem('token');
    }, []);
    const [cartdata, setCartdata] = useState('')
    useEffect(() => {
        async function cart() {
            try {
                const response = await fetch('http://art.alrawdah.in/api/auth/viewcart', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${storedToken}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCartdata(data);
            } catch (error) {
                console.error('Error in API:', error);
            }
        }


        cart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const totalPrice = cartdata.reduce((total, item) => total + item.total_price, 0);
    let totalPrice = 0;
    if (Array.isArray(cartdata)) {
        totalPrice = cartdata.reduce((total, item) => total + item.total_price, 0);
    } else {
        // Handle the case where cartdata is not an array (e.g., show an error message)
        // You can set a default or error value for totalPrice
        totalPrice = -1; // Set a default value or handle the error as needed
    }
    // const cartInfoId = cartdata.length > 0 ? cartdata[0].cart_info_id : null;
    const cartInfoId = cartdata.length > 0 ? cartdata[0].cart_info_id : null;
    // const [loading, setLoading] = useState(true);
    return (

        <div>
            <nav className="navbar fixed-top  bg-white  ">
                <div className="container" id="Cart-header" style={{ padding: "10px 10px" }} >
                    <Link className="navbar-brand " to="/">
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>

                    </Link>
                    <h4 id='cart_heading'>Cart</h4>
                </div>
            </nav>
            <section id="menu" className="menu">
                {
                    console.log(cartdata)
                }
                {
                    // loading
                    cartdata.length > 0 ?

                        cartdata ?

                            <div className="container">
                                <div className="row menu-container">
                                    {
                                        cartdata.map((item, index) =>
                                            <div className="col-lg-6 menu-item " key={index}>
                                                <div className="menu-content">
                                                    <div className="menu-details">
                                                        {
                                                            item.type === 1 ? (
                                                                <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                                                            ) : item.type === 2 ? (
                                                                <span><img src='./assets/non_veg.png' alt='non-veg' className='menu-image' /></span>
                                                            ) : (
                                                                <span></span>
                                                            )
                                                        }

                                                        <div className="menu-description">
                                                            <Link to="#">{item.name}</Link><br></br>

                                                            <div className="Addtocart_card ">

                                                                <div className="quantity-input-cart">

                                                                    <button className="quantity-btn-cart" onClick={() => updatecart(item, 'decrease')}>-</button>
                                                                    <div className="quantity-value-cart" >{item.quantity}</div>
                                                                    <button className="quantity-btn-cart" onClick={() => updatecart(item, 'increase')} >+</button>
                                                                </div>


                                                            </div>



                                                        </div>
                                                        <div className="menu-price">
                                                            <span>Rs {item.rate}</span>
                                                            <h4 className='pt-2'>₹ {item.total_price
                                                            }</h4>
                                                        </div>
                                                        <span style={{ paddingLeft: "20px" }}>
                                                            <i className="fa fa-trash" aria-hidden="true" style={{ color: "grey" }} onClick={() => { Deleteitem(item) }}></i>
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            : <h4>Loading...</h4> :
                        <div className='Cart'>
                            <div className='text-center'>
                            <img src="./assets/shopping.png" className="img-fluid z-depth-1 rounded-circle"
                                alt="logo" width="100" height="100" />
                            <h4>{cartdata.message}</h4>
                            <p className='text-secondary'>
                                Looks like you haven't added <br></br> anything yet to your cart
                            </p>
                            </div>
                            
                        </div>
                }
            </section>
            {
                cartdata.length > 0 ?

                    <div className='fixed-bottom' >
                        <Card >

                            <Card.Body>

                                {/* <Card.Text> */}

                                <ul>
                                    <li className="d-flex justify-content-between"><span>Item total</span><span className='price'>0.0 ₹</span></li>
                                    <li className="d-flex justify-content-between"><span>Tax</span><span className='price'>0.0 ₹</span></li>
                                    <li className="d-flex justify-content-between"><span>To pay</span><span className='price'> {totalPrice} ₹</span></li>
                                </ul>

                                <div className='text-center'>
                                    <button className='order_button' onClick={() => setModalShow(true)}>Order Now</button>

                                </div>
                                <OrdernowPopup
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    grandtotal={totalPrice}
                                    cartid={cartInfoId}
                                />


                                {/* </Card.Text> */}

                            </Card.Body>
                        </Card>
                    </div> : null
            }

        </div>
    )
}

export default Addtocart
