import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'; import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
// import { useLocation } from 'react-router-dom';
function MyVerticallyCenteredModal(props) {
    // increment/decrement for full
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState("")

    const path = 'https://art.alrawdah.in/storage/';
    const decreaseQuantity = () => {
        if (currentQuantity > 1) {
            setCurrentQuantity(currentQuantity - 1);
            console.log(currentQuantity)
        }
    };

    const increaseQuantity = () => {
        setCurrentQuantity(currentQuantity + 1);
    };
    useEffect(() => {
        console.log(currentQuantity);
    }, [currentQuantity]);
    
    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Place Your Order
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
            {
          props.itemdata?.img_path ? (
            <img src={path + props.itemdata.img_path} className="rounded mx-auto d-block" alt="fresh juice" height="200" />) : (
            <img src="./assets/restaurant.webp" className="rounded mx-auto d-block" alt="fresh juice" height="200" />)
        }
                {console.log(props.itemdata)}
                <div className='d-flex p-2'>
                    <h4>{props.itemdata?.name || 'error in props'}</h4> &nbsp;&nbsp;
                    {
            props.itemdata === 1 ? (<img src='./assets/veg.png' alt='veg' />) :
              <img src='./assets/non_veg.png' alt='non-veg' />
          }
                </div>
                <div>
                    <i className="fa fa-clock-o"></i> <span>30 min</span>  <span style={{ color: " #fdbe33" }}>&nbsp;4.5 <i className="fa fa-star" style={{ color: "gold" }}></i> </span>
                </div>

                <div>
                    {props.itemdata?.variants.map((variant, index) => (
                        <div key={index} >
                            <input
                                type="radio"
                                id={`variant-${variant.id}`}
                                name="variant"
                                value={variant.variant_name}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setSelectedVariant(e.target.value);
                                }}
                            />
                            <label htmlFor={`variant-${variant.id}`} style={{ paddingLeft: "9px" }}>
                                {variant.variant_name} </label>
                            <label htmlFor={`variant-${variant.id}`} style={{ float: "right", fontWeight: "600" }}> ₹ {variant.price_adjustment} </label>
                        </div>
                    ))}
                </div>

            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-between" }}>
                <div className="quantity-input ">
                    <button className="quantity-btn" onClick={decreaseQuantity}>-</button>
                    <div className="quantity-value" >{currentQuantity}</div>
                    <button className="quantity-btn" onClick={increaseQuantity} >+</button>
                </div>
                <button onClick={() => Addtocart(props.itemdata, currentQuantity, selectedVariant)} className='button_add text-decoration-none '>Add to cart</button>
            </Modal.Footer>
        </Modal>
    );
}
async function Addtocart(item, quantity, selectedVariant) {
    // console.log(item);
    console.log("Item:", item);
    console.log("Quantity:", quantity);
    console.log("Selected Variant:", selectedVariant);
    const storedToken = localStorage.getItem('token');
    console.log(storedToken)
    const selectedVariantInfo = item.variants.find(
        (variant) => variant.variant_name === selectedVariant
    );
    console.log(selectedVariantInfo)
    // if (selectedVariantInfo) {
    //   console.log("Selected Variant Info:", selectedVariantInfo);
    // } else {
    //   console.log("Variant not found for selectedVariant:", selectedVariant);
    // }
    try {
        const cartdata = await fetch('http://art.alrawdah.in/api/auth/addcart', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${storedToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "item_id": item.id, "item_price": selectedVariantInfo.price_adjustment, "item_quantity": quantity, "type": item.type, "plate_portion": selectedVariant })
        })
        if (cartdata.ok) {
            const data = await cartdata.json();
            console.log(data)
            toast.success(data.message)


        }
        else if (cartdata.status === 401) {
            toast.error('Unauthorized response');
        } else {
            toast.error('Something went wrong with the Add to cart request request.');
        }
    }
    catch (error) {
        console.log(error)
    }
}

async function Addcart(item) {
    const quantity = 1;
    const portion = "full"
    const storedToken = localStorage.getItem('token');
    console.log(item)
    try {
        const cartdata = await fetch('http://art.alrawdah.in/api/auth/addcart', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${storedToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "item_id": item.id, "item_price": item.base_price
                , "item_quantity": quantity, "type": item.type, "plate_portion": portion
            })
        })
        if (cartdata.ok) {
            const data = await cartdata.json();
            console.log(data)
            toast.success(data.message)


        }
        else if (cartdata.status === 401) {
            toast.error('Unauthorized response');
        } else {
            toast.error('Something went wrong with the Add to cart request request.');
        }
    }
    catch (error) {
        console.log(error)
    }
}
function Messagepopup(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please  Add variants
                </Modal.Title>
            </Modal.Body>
        </Modal>
    );
}
const Todaysspecial = () => {
    const [modalShow, setModalShow] = useState(false);
    const [popupshow, setPopupshow] = useState(false);
    // const location = useLocation();
    // const itemData = location.state.itemData;
    // const itemHeading = location.state.itemName;
    // popup function open and send data
    const [selectedItemData, setSelectedItemData] = useState(null);
    const handleAddButtonClick = (item) => {
        setSelectedItemData(item); // Set the selected item data
        setModalShow(true); // Open the modal
    };
    // Token Get
    const [token, setToken] = useState('null')
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
    }, [])
    const [Special, setSpecialitem] = useState(null);
    const [filterdata,setFilterdata]=useState([]);
const [query ,setQuery]=useState('');
    // fetch Menu Api
    useEffect(() => {
        async function todaysspecial() {
            try {
                const response = await fetch('https://art.alrawdah.in/api/auth/special');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSpecialitem(data);
                setFilterdata(data)

            } catch (error) {
                console.error('Error in API:', error);
            }
        }


        todaysspecial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
const handleSearchInputChange=(event)=>{
    const getsearch=event.target.value;
   setQuery(getsearch)

   if(getsearch.length>0)
   {
    const searchdata=Special.filter((item)=>item.name.toLowerCase().includes(getsearch))
    setSpecialitem(searchdata)
   }else{
    setSpecialitem(filterdata)
   }
}
    return (
        <div>
            <nav className="navbar fixed-top  bg-white  ">
                <div className="container" style={{ padding: "10px 10px" }} >
                    <Link className="navbar-brand" to="/">
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>

                    </Link>
                    <div className="search">
            <span className="fa fa-search" style={{ color: "grey" }}></span>
            <input placeholder="Search" value={query}
              onChange={(e)=>handleSearchInputChange(e)} />
          </div>
                </div>

            </nav>
            <section id="menu" className="menu">
                <div className="container">
                    <div className="section-title">
                        <h2>Today's Special</h2>
                    </div>
                    <div className="row menu-container">
                        {
                            Special ? Special.map((item, index) =>
                                <div className="col-lg-6 menu-item  " key={index}>
                                    <div className="menu-content">
                                        <div className="menu-details">
                                        {
                        item.type === 1 ? (
                          <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                        ) : item.type === 2 ? (
                          <span><img src='./assets/non_veg.png' alt='non-veg' className='menu-image' /></span>
                        ) : (
                          <span></span>
                        )
                      }
                                            <div className="menu-description">
                                                <Link to="#">{item.name}</Link>
                                                <p>({item.description})</p>
                                            </div>
                                            <div className="menu-price">
                                                <span>Rs {item.base_price}</span>
                                                {
                                                    token ? (
                                                        item.is_variant_available === 0 ? (
                                                            <button onClick={() => Addcart(item)} className="dish_login">Add</button>
                                                        ) : item.is_variant_available === 1 ? (
                                                            item.variants.length === 0 ? (
                                                                <button className="btn btn-primary" onClick={() => setPopupshow(true)}>
                                                                    Add
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-primary" onClick={() => handleAddButtonClick(item)}>
                                                                    Add
                                                                </button>
                                                            )
                                                        ) : null // Handle other cases as needed
                                                    ) : (
                                                        <Link to='/login' className="dish_login">Add</Link>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ) : <h4>Loading...</h4>
                        }


                        {/* <!-- Today's Special Ends --> */}
                    </div> 



                    
                </div>
            </section>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                itemdata={selectedItemData} // Pass the selected item data to the modal
            />
            {/* Message Popup Variant is 0 */}
            <Messagepopup
                show={popupshow}
                onHide={() => setPopupshow(false)}
            />
            {/*<!-- Float Cart--> */}

            {
                token ?
                    // cart screen
                    (<div className="float_cart">
                        <Link to="/addtocart"> <img alt="cart" src="./assets/carts.png" width="50px" height="50px" /></Link>
                    </div>) :
                    // onClick={() => setModalShow(true)}
                    // cart login
                    (<div className="float_cart">
                        <Link to='/login'> <img alt="cart" src="./assets/carts.png" width="50px" height="50px" /></Link>
                    </div>)
            }
            {/*     <!-- Float Cart End --> */}
        </div>
    )
}

export default Todaysspecial
