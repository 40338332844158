import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
// import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [token, setToken] = useState('');
    const navigate =useNavigate()
    useEffect(() => {

        // Retrieve token from local storage and update the state
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);

    }, []);
    async function logout() {

        

        try {
            const response = await fetch("http://art.alrawdah.in/api/auth/logout", {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const logout_data = await response.json();
            if (logout_data) {
                // Remove the token from local storage and perform any other logout tasks
                localStorage.removeItem('token');
                navigate("/login");
                // alert('Logout successful!');
                // Redirect to the login page or any other action after logout
            } else {
                alert('Logout failed. Please try again.');
            }
            console.log(logout_data)
        }
        catch (error) {
            console.error('Error during logout:', error);
            alert('An error occurred during logout.');
        }

    }
    return (
        <div>
            <nav className="navbar fixed-top  bg-white  ">
                <div className="container" style={{ padding: "10px 10px" }} >

                    {/* <img onClick={handleShow} src="./assets/menu.png" alt="Bootstrap"  id='toggle'/> */}

                    {/* <i className="fa fa-bars" onClick={handleShow} style={{ color: "#ff9933" }} aria-hidden="true" id='toggle'></i> */}

                    <Link className="navbar-brand" to="/">
                        {/* <img src="./assets/back.png" alt="Back" /> */}
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </Link>
                </div>

            </nav>

            {/* <div>

                <Offcanvas show={show} onHide={handleClose} style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title style={{ color: "#ff9933" }}>QR MENU</Offcanvas.Title>
                    </Offcanvas.Header>
                    <ul className='canvas_links'>
                        <li ><Link to="/">Home</Link></li>
                        <li><Link to="/profile">Profile</Link></li>
                        <li><Link to="">Ask for Bill</Link></li>
                        <li><Link to="/askquestion">Ask Question</Link></li>
                        <li><Link to="/feedback">Feedback</Link></li>

                    </ul>
                    <footer className="text-center p-3 fixed-bottom" style={{ width: "195px" }}>
                        <button className='button_add' onClick={logout}>Logout</button>
                    </footer>
                </Offcanvas>
                
            </div> */}
            <button className='button_add' onClick={logout}>Logout</button>

        </div>
    )
}

export default Sidebar
