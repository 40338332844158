import './App.css';
import{BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './components/Home';
import Starters from './components/categories/Starters';
import Salads from './components/categories/Salads';
import Login from './components/Login';
import Addtocart from './components/Addtocart';
import Askquestion from './components/Askquestion';
import Profile from './components/Profile';
import Feedback from './Feedback';
import Dishes from './components/categories/Dishes';
import Otp from './components/Otp';
import Todaysspecial from './components/categories/Todaysspecial';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Thankyoupage from './components/categories/Thankyoupage';
function App() {
  return (
    <div>
      <ToastContainer position="top-center"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={true}
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"/>
        <BrowserRouter>
           
              <Routes >
                <Route path="/" element={<Home></Home>}></Route>
                <Route path='/starters' element={<Starters/>} ></Route>
                <Route path='/salads' element={<Salads/>}></Route>
                <Route path='/dishes' element={<Dishes/>}></Route>
                <Route path='/login' element={<Login/>} ></Route>
                <Route path='/addtocart' element={<Addtocart></Addtocart>}></Route>
                <Route path='/askquestion' element={<Askquestion/>}></Route>
                <Route path='/profile' element={<Profile/>} ></Route>
                <Route path='/feedback' element={<Feedback/>}></Route>
                <Route path='/otp'element={<Otp/>}></Route>
                <Route path='/todaysspecial' element={<Todaysspecial/>}></Route>
                <Route path='/thankyoupage' element={<Thankyoupage/>}></Route>
                <Route path='*'element={<h1>404 error</h1>}></Route>
              </Routes>
            
        </BrowserRouter>
    </div>
  );
}

export default App;
