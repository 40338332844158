import React from 'react'
import { Link } from 'react-router-dom'
import Searchback from '../Searchback'
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { NavDropdown } from 'react-bootstrap';
import { useState } from 'react';
function MyVerticallyCenteredModal(props) {
  // increment/decrement for full
  const [currentQuantity, setCurrentQuantity] = useState(1);
  const [halfCurrentQuantity, setHalfCurrentQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (currentQuantity > 1) {
      setCurrentQuantity(currentQuantity - 1);
    }
  };

  const increaseQuantity = () => {
    setCurrentQuantity(currentQuantity + 1);
  };

  const halfDecreaseQuantity = () => {
    if (halfCurrentQuantity > 1) {
      setHalfCurrentQuantity(halfCurrentQuantity - 1);
    }
  }
  const halfIncreaseQuantity = () => {
    setHalfCurrentQuantity(halfCurrentQuantity + 1)
  }

  return (

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Place Your Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src="./assets/11.webp" className=" rounded mx-auto d-block" alt="fresh juice" height="200" />
        <div className='d-flex p-2'>
          <h4>FRESH JUICES</h4> &nbsp;&nbsp;
          <img src='./assets/non_veg.png' alt='non-veg' />
        </div>
        <div>
          <i className="fa fa-clock-o"></i> <span>30 min</span>  <span style={{ color: " #fdbe33" }}>&nbsp;4.5 <i className="fa fa-star" style={{color:"gold"}}></i> </span>
        </div>

        <div className='d-flex justify-content-evenly'>
          <label>Full</label>
          <span>₹400</span>
          {/* <NavDropdown title="Add Quantity" id="nav-dropdown">
              <NavDropdown.Item eventKey="4.1">Regular</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Medium</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3">Large</NavDropdown.Item>
           
           </NavDropdown> */}
          <div className="quantity-input">
            <button className="quantity-btn" onClick={decreaseQuantity}>-</button>
            <div className="quantity-value" >{currentQuantity}</div>
            <button className="quantity-btn" onClick={increaseQuantity} >+</button>
          </div>
        </div>
        <div className='d-flex justify-content-evenly pt-1'>
          <label>Half</label>
          <span>₹200</span>
          <div className="quantity-input">
            <button className="quantity-btn" onClick={halfDecreaseQuantity}>-</button>
            <div className="quantity-value" >{halfCurrentQuantity}</div>
            <button className="quantity-btn" onClick={halfIncreaseQuantity} >+</button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide} className='button_add' >Add to cart</Button> */}
        <Link to='/addtocart' className='button_add text-decoration-none '>Add to cart</Link>
      </Modal.Footer>
    </Modal>
  );
}
const Salads = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <Searchback></Searchback>

      <section id="menu" className="menu">
        <div className="container">
          {/* <div style={{backgroundColor:"whitesmoke ",padding:"5px 0px"}}>
            <label class="toggle">
              <input type="checkbox" id="toggleButton" />
              <span class="slider"></span>
            </label>
          </div> */}
          <div className="section-title">
            {/* <h2>Breakfast &nbsp;<span>&nbsp;A La Carte</span></h2> */}
            <h2>Salads</h2>
          </div>
          <div className="row menu-container">
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">FRESH JUICES</Link>
                    <p>(Apple, orange, pineapple, mix fruits)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary" onClick={() => setModalShow(true)} >Add</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">FRESH CUT FRUITS</Link>
                    <p>(Plater of seasonal cut fresh fruits)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 350</span>
                    <button className="btn btn-primary" onClick={() => setModalShow(true)} >Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">BREAKFAST CEREALS</Link>
                    <p>(Cornflakes, chocos, fruit and nut served with hat/cold milk)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">EGGS TO ORDER</Link>
                    <p>(Boiled,fried,scrambled poached egg 'served with grilled tomato & french fries')</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">IDLI</Link>
                    <p>(Steamed rice and lentil cake served with sambhar and chutney)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 75</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">DOSA</Link>
                    <p>(South indian fermented rice pancake served plain or with topping of the onion, tomatoes and chopped coriander served with sambhar and chutney)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 350</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">PARANTHA</Link>
                    <p>(Whole wheat bread, grilled with with filling of your choices 'patato, cottage chease served with pickle and yoghurt')</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">POORI BHAJI</Link>
                    <p>(Whole wheat bread deef fried and served with indian spiced patato and green peas preparation)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Starters Menu end --> */}
          </div>
        </div>
      </section>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
   
           {/*<!-- Float Cart--> */}

      <div className="float_cart">
        <Link aria-label="Cart" to="/login" >
          <img alt="cart" src="./assets/carts.png" width="50px" height="50px" /></Link>
      </div>
      {/*     <!-- Float Cart End --> */}


    </div>

  )
}

export default Salads
