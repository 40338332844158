import React from 'react'
import { Link } from 'react-router-dom'
const Searchback = () => {
  return (
    <div>
      <nav className="navbar fixed-top  bg-white  ">
        <div className="container" style={{ padding: "10px 10px" }} >
          <Link className="navbar-brand" to="/">
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>

          </Link>

          {/* <Link className="navbar-brand" to=""> */}
            {/* <img src="./assets/search.png" alt="Bootstrap" /> */}
            <div className="search">
              <span className="fa fa-search" style={{color:"grey"}}></span>
              <input placeholder="Search" />
            </div>
             {/* <div class="form-group has-search">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control" placeholder="Search"/>
  </div> */}
          {/* </Link> */}
        </div>

      </nav>
    </div>
  )
}

export default Searchback
