import React from 'react'
import Sidebar from './Sidebar'

const Profile = () => {
  return (
    <div>
      <Sidebar />
      <div className='container' style={{ paddingTop: "80px" }}>
        <h2 className='text-center' style={{ padding: "20px 0px" }}>Profile</h2>
        <div className='text-center profile_section '>
          <div className='row'>
            <div className='col-md-4'>
              <i className="fa fa-user" aria-hidden="true"></i> &nbsp;
              <label className='profile_label'>Name</label>
              <h3 className='profile_att'>Admin</h3>
            </div>
            <div className='col-md-4'>
              <i className="fa fa-phone" aria-hidden="true"></i> &nbsp;
              <label className='profile_label'> Phone No.</label>
              <h3 className='profile_att' >9874561236</h3>
            </div>
            <div className='col-md-4'>
              <i className="fa fa-unlock-alt" aria-hidden="true"></i> &nbsp;
              <label className='profile_label'>Password</label>
              <h4 className='profile_att'>qq*******pp</h4>
            </div>
          </div>
        </div>


      </div>

    </div>
  )
}

export default Profile
