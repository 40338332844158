import React from 'react'
import { Link } from 'react-router-dom'
const Thankyoupage = () => {
  return (
    <div className='container'>
         <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="col-md-4">
                <div className="card  bg-white shadow p-5">
                    <div className="mb-4 text-center">
                        <img src='./assets/check-mark.webp' alt='checkmark' width='75' height='75'/>
                    </div>
                    <div className="text-center">
                        <h1>Thank You !</h1>
                        <p>Savor the Flavor, Thank You for Dining with Us! </p>
                        <Link to='/' className="btn btn-outline-success">Back Home</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Thankyoupage
