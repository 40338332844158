import React from 'react'
import { Link } from 'react-router-dom'
import Searchback from '../Searchback'
const Starters = () => {
  return (
    <div>
      <Searchback></Searchback>
      <section id="menu" class="menu">
        <div class="container">
          <div class="section-title">
            {/* <h2>Breakfast &nbsp;<span>&nbsp;A La Carte</span></h2> */}
            <h2>Starters</h2>
          </div>
          <div class="row menu-container">
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">FRESH JUICES</Link>
                    <p>(Apple, orange, pineapple, mix fruits)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">FRESH CUT FRUITS</Link>
                    <p>(Plater of seasonal cut fresh fruits)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 350</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">BREAKFAST CEREALS</Link>
                    <p>(Cornflakes, chocos, fruit and nut served with hat/cold milk)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">EGGS TO ORDER</Link>
                    <p>(Boiled,fried,scrambled poached egg 'served with grilled tomato & french fries')</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">IDLI</Link>
                    <p>(Steamed rice and lentil cake served with sambhar and chutney)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 75</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">DOSA</Link>
                    <p>(South indian fermented rice pancake served plain or with topping of the onion, tomatoes and chopped coriander served with sambhar and chutney)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 350</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/non_veg.png' alt='nonveg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">PARANTHA</Link>
                    <p>(Whole wheat bread, grilled with with filling of your choices 'patato, cottage chease served with pickle and yoghurt')</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 menu-item ">
              <div className="menu-content">
                <div className="menu-details">
                  <span><img src='./assets/veg.png' alt='veg' className='menu-image' /></span>
                  <div className="menu-description">
                    <Link to="#">POORI BHAJI</Link>
                    <p>(Whole wheat bread deef fried and served with indian spiced patato and green peas preparation)</p>
                  </div>
                  <div className="menu-price">
                    <span>Rs 300</span>
                    <button className="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Starters Menu end --> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Starters
