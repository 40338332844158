import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>

      <div className="container my-5">


        <footer style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
          <div className="container p-4">
            <div className="row">

              <div className="col-lg-6 col-md-6 col-sm-6  footer_size " style={{ paddingTop: "44px" }}>

                <ul className="list-unstyled mb-0 " id="footer-ul">

                  <li className='footer-li lazyload'> <img src="./assets/pin.webp" data-src='./assets/pin.webp' alt='laptop' /> &nbsp;<Link className='text-dark text-decoration-none' to="">Chanapora,Srinagar</Link></li><br></br>
                  <li className='footer-li lazyload'> <img src="./assets/call.webp" data-src='./assets/call.webp' alt='phone' />  &nbsp;<Link to="tel:8899436553" className='text-dark text-decoration-none'>+91-8899436553</Link></li><br></br>
                  <li className='footer-li lazyload'><img src="./assets/email.webp" data-src='./assets/email.webp' alt='email' /> &nbsp;<Link to="mailto:info@mybigplate.com" className='text-dark text-decoration-none'>info@mybigplate.com</Link></li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 footer_size">
                <img src='./assets/mnp.webp' alt='logo' id="src_logo"  ></img>
              </div>
            </div>
          </div>


        </footer>

      </div>

    </div>
  )
}

export default Footer
