import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify'
function CartLogin(props) {
  // cart Login pop up
  const [name, setName] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const navigatetologin = useNavigate()
  async function register(e) {
    e.preventDefault();
    try {
      const result = await fetch('http://art.alrawdah.in/api/auth/register', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ "customer_name": name, "customer_number": phonenumber })
      })
      if (result.ok) {
        const data = await result.json();
        console.log(data)
        console.log(data.user.customer_number)
        toast.success('OTP Send');
        navigatetologin('/otp', { state: { otp: data.otp, number: data.user.customer_number } })
      } else if (result.status === 401) {
        toast.error('Invalid username or password.');
      } else {
        toast.error('Something went wrong with the login request.');
      }
    }
    catch (error) {
      toast.error('Error during login:', error);
    }

  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={register}>
          <h4 className='text-center' style={{ color: "#ff9933" }}>Please Fill Form</h4>
          <input type="text" className="form1" name='name' placeholder="Name" required onChange={(e) => { setName(e.target.value) }} />
          <input type="tel" className="form1" name='phone number' placeholder="phone Number" maxLength={10} required onChange={(e) => { setPhonenumber(e.target.value) }} />
          <button type="submit">Send OTP</button>
        </form>

      </Modal.Body>
    </Modal>
  );
}

const Home = () => {
  const [menuitem, setMenuitem] = useState(null);
  // fetch Menu Api
  useEffect(() => {
    async function menu() {
      try {
        const response = await fetch('https://art.alrawdah.in/api/auth/menudata');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMenuitem(data);
      } catch (error) {
        console.error('Error in API:', error);
      }
    }
    menu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch menuitems API
  const navigate = useNavigate();
  const handleButtonClick = async (itemId, itemname) => {
    try {
      const response = await fetch("https://art.alrawdah.in/api/auth/menuitems", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ cat_id: itemId }), // Corrected the structure here
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("POST request successful", data);
      navigate(`/dishes`, { state: { itemData: data, itemName: itemname } });
      console.log(`Button clicked for item with ID: ${itemId},${itemname}`);
    } catch (error) {
      console.error('Error in POST request:', error);
    }
  };

  const path = 'https://art.alrawdah.in/storage/';
  const [modalShow, setModalShow] = useState(false);

  // Token Get
  const [token, setToken] = useState('null')
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, [])
  useEffect(() => {
    console.log("token", token);
  }, [token]);
  // console.log("token", token)



  return (
    <div>
      {/* ============================= Header ================================= */}
      <Navbar></Navbar>
      {/*==================================== Top Carosel ================================*/}

      <div className='container col-sm-12' id='carosal-container'>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 lazyload" // Add the lazyload class
              src="./assets/slide2.webp" // Replace with a small placeholder image
              data-src="./assets/slide2.webp" // Actual image source
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 lazyload"
              src="./assets/slide3.webp"
              data-src="./assets/slide3.webp"
              alt="second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 lazyload"
              src="./assets/slide4.webp"
              data-src="./assets/slide4.webp"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      {/*================================== Top Carosel Ends=============================== */}
      {/*========================= Ready To Go ============================= */}
      <div className='container'>
        <div className='container d-block todays_special'>
          <div className='row today_section'  >
            <div className='col-md-5 ready_to_go_top'>
              <div className='todays_special_img'>
                <img src="./assets/biryani.webp" data-src='./assets/biryani.webp' className="img-fluid z-depth-1 rounded-circle lazyload"
                  alt="biryani" width="150" height="150" />
              </div>

            </div>
            <div className='col-md-6 ready_to_go_bottom '>
              <div className='ready_to_go'>
                <h2>Today's <span>Special</span></h2>
                <ul className='today_special'>
                  <li>Ready to serve</li>
                  <li>Top ordered dish</li>
                  <Link to='/todaysspecial' className='btn' id='ready_to_go_button' >View</Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===========================Ready To Go Ends============================== */}

      {/*================================ Category Start ================================*/}
      <div className='container pt-3 pb-3'>
        <h3 >What would you like to <span style={{ color: "#ff9933" }}>order</span>?</h3>
        <div className='row'>
          {menuitem ?
            menuitem.map((item, index) =>
              <div className='col-md-3 ittem ' key={index} >
                <button className="project-description " onClick={() => {
                  handleButtonClick(item.id, item.category_name);
                }}>
                  <div className="column lazyload" id='category-column'>
                    <img className="img-responsive column2" alt=" category" width="100%" src={path + item.img_path} data-src={path + item.img_path} />
                    <h3>{item.category_name}</h3>

                  </div>
                </button>

              </div>
            )
            : <h3>Loading...</h3>
          }
        </div>
      </div>
      {/* =============================== Category Ends================================== */}

      {/* ============================= Float Cart ===================================== */}

      {
        token ?
          // cart screen
          (<div className="float_cart">
            <Link to="/addtocart"> <img alt="cart" src="./assets/carts.webp" width="50px" height="50px" /></Link>
          </div>) :
          // onClick={() => setModalShow(true)} 
          // cart login
          (<div className="float_cart">
            <img alt="cart" src="./assets/carts.webp" width="50px" height="50px" onClick={() => setModalShow(true)} />
          </div>)
      }
      {/* ======================== Float Cart End ================================== */}
      <CartLogin
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* ============================ Footer========================================= */}
      <Footer />
    </div>
  )
}

export default Home
