import React, { useState ,useEffect} from 'react'
import Sidebar from './Sidebar'
import { Card } from 'react-bootstrap';
import { useNavigate,Link } from 'react-router-dom';
import {  toast } from 'react-toastify';

const Login = () => {
    const [name,setName]=useState();
    const [phonenumber,setPhonenumber]=useState();
    const navigate=useNavigate()
    async function register(e){
        e.preventDefault();
        try{
             const result =await fetch('http://art.alrawdah.in/api/auth/register',{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({ "customer_name":name,"customer_number":phonenumber })
             })
             if(result.ok){
                const data = await result.json();
                console.log(data)
                console.log(data.user.customer_number)
                toast.success(data.message );
                navigate('/otp' ,{state : {otp: data.otp, number:data.user.customer_number} })
             }else if(result.status === 401) {
                toast.error('Unauthorized response');
              } else {
                toast.error('Something went wrong with the login request.');
              }
        }
        catch(error)
        {
            toast.error('Error during login:', error);
        }
       
    }

    const [token, setToken] = useState('');
    const logoutnavigate =useNavigate()
    useEffect(() => {

        // Retrieve token from local storage and update the state
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);

    }, []);
    async function logout() {
        try {
            const response = await fetch("http://art.alrawdah.in/api/auth/logout", {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const logout_data = await response.json();
            if (logout_data) {
                // Remove the token from local storage and perform any other logout tasks
                localStorage.removeItem('token');
                logoutnavigate("/");
                toast.success(logout_data.message)
                // alert('Logout successful!');
                // Redirect to the login page or any other action after logout
            } else {
                toast.error('Logout failed. Please try again.');
            }
            console.log(logout_data)
        }
        catch (error) {
            toast.error('Error during logout:', error);
            // alert('An error occurred during logout.');
        }

    }
    return (
        <div>
            <Sidebar></Sidebar>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="container">
                    <form  onSubmit={register} style={{ padding: "8px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                        <h4 className="text-center" style={{ color: "#ff9933" }} >Please Fill Form</h4>
                        <input type="text" onChange={(e)=>{setName(e.target.value)}} className="form1" placeholder="Name" required maxLength={15} />
                        <input type="tel" onChange={(e)=>{setPhonenumber(e.target.value)} }className="form1" placeholder="Phone Number" required maxLength={10}/>
                        <button type="submit">Send OTP</button>
                    </form>
                </div>
            </div>
            {
                token?<div className='fixed-bottom' >
                <Card >
                    <Card.Body>                     
                                <div className="d-flex justify-content-between"> <button className='button_add' onClick={logout}>Logout</button><Link to="/feedback" className='text-white text-decoration-none button_add'>Feedback</Link></div>
                    </Card.Body>
                </Card>
            </div>:(null)
            }
            
        </div>

    )
}

export default Login

