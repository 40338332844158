import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';

const Otp = () => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [error, setError] = useState(null); // State for error handling
  const getotp = useLocation();
  const OTP = getotp.state.otp;
  const Number = getotp.state.number;
  const navigate=useNavigate()

  const otpChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < 3) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      const result = await fetch('http://art.alrawdah.in/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customer_number: Number, password: OTP }),
      });

      if (result.ok) {
        const data = await result.json();
        console.log(data);
        localStorage.setItem('token', data.access_token);
        navigate('/')
        toast.success(data.message)
        
      } else if (result.status === 403) {
        // Handle "403 Forbidden" error by setting an error message
        setError('Access denied. Please check your credentials.');
      } else {
        // Handle other errors
        setError('An error occurred. Please try again later.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('An error occurred:', error);
    }
  };

  // Conditionally render OTP form or error message
  return (
    <div>
        
      {error ? (
        <h1>Error: {error}</h1>
      ) : (
        <div>
          <h1>Your OTP is {OTP}</h1>
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="container">
              <form
                onSubmit={login}
                style={{ padding: '8px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
              >
                <h4 className="text-center" style={{ color: '#ff9933' }}>
                  OTP
                </h4>
                <div className="otp-input-container ">
                  <InputGroup>
                    {otp.map((digit, index) => (
                      <FormControl
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        value={digit}
                        onChange={(e) => otpChange(e, index)}
                        className="otp-input form1"
                        maxLength="1"
                      />
                    ))}
                  </InputGroup>
                </div>
                <button type="submit">LOGIN</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Otp;
