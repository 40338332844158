import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
const FoodRaitingStar = ({ isSelected, onClick }) => (
    <span className={`star ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        &#9733;
    </span>
);
const ServiceRaitingStar = ({ isSelected, onClick }) => (
    <span className={`star ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        &#9733;
    </span>
);
const AmbienceRaitingStar = ({ isSelected, onClick }) => (
    <span className={`star ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        &#9733;
    </span>
);
const Feedback = () => {
    const [foodSelectedStars, setFoodSelectedStars] = useState(0);
    
    const  foodStarClick = (index) => {
        setFoodSelectedStars(index + 1);
    };

    const foodstars = [];
    for (let i = 0; i < 5; i++) {
        foodstars.push(
            <FoodRaitingStar
                key={i}
                isSelected={i < foodSelectedStars}
                onClick={() => foodStarClick(i)}
            />
        )
    }
    const [serviceSelectedStars, setServiceSelectedStars] = useState(0);
    const  serviceStarClick = (index) => {
        setServiceSelectedStars(index + 1);
    };
    const servicestars = [];
    for (let i = 0; i < 5; i++) {
        servicestars.push(
            <ServiceRaitingStar
                key={i}
                isSelected={i < serviceSelectedStars}
                onClick={() => serviceStarClick(i)}
            />
        )
    }
    const [ambienceSelectedStars, setAmbienceSelectedStars] = useState(0);
    const  ambienceStarClick = (index) => {
        setAmbienceSelectedStars(index + 1);
    };
    const ambiencestars = [];
    for (let i = 0; i < 5; i++) {
        ambiencestars.push(
            <AmbienceRaitingStar
                key={i}
                isSelected={i < ambienceSelectedStars}
                onClick={() => ambienceStarClick(i)}
            />
        )
    }
    return (
        <div>
            <nav className="navbar fixed-top  bg-white  ">
                <div className="container" style={{ padding: "10px 10px" }} >
                    <Link className="navbar-brand" to="/login">
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>

                    </Link>
                </div>
            </nav>
            <div className='container' style={{ paddingTop: "80px" }} >
                <h3 className='text-center fw-normal'>Rate Your Experience</h3>
                <h5 className='text-center fw-light'>Are you satisfied with the service</h5>
                <div className='row p-3'>
                    <div className='col-md-6'>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control feedback_input_border" id="floatingName" placeholder="Enter Your Name" />
                            <label htmlFor="floatingName">Enter your name</label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control feedback_input_border" id="floatingEmail" placeholder="name@example.com" />
                            <label htmlFor="floatingEmail">Enter your email</label>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control feedback_input_border" id="floatingPhone" placeholder="Enter Your Name" />
                            <label htmlFor="floatingPhone">Enter your phone number </label>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="form-floating">
                            <textarea className="form-control feedback_input_border" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            <label htmlFor="floatingTextarea2">Tell us how can we improve</label>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className='text-center text-danger fw-light'>All ratings are mendatory</h5>
                    <div className='d-flex justify-content-between'>
                        <label className='star_label'>Food Raiting</label>
                        <p>
                        <span className="rating">{foodstars}</span>
                        </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <label className='star_label'>Services Raiting</label>
                        <p>
                        <span className="rating">{servicestars}</span>
                        </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <label  className='star_label'>Ambience Raiting</label>
                        <p>
                            <span className="rating">{ambiencestars}</span>
                        </p>
                    </div>
                    <div className='text-center'>
                        <button className='button_add' > Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;
